<template>
  <div>
    <span class="my-3 d-block text-primary-dark font-weight-bold">
      {{ $t("Rating") }}
    </span>
    <b-col class="rating-container mb-3">
      <b-row class="mt-2 py-2 justify-content-between">
        <div>
          <h3 class="d-inline text-primary-dark font-weight-bold">
            {{ school.rating | numeral("0.0") }}
          </h3>
          <span
            class="d-inline mt-2 float-bottom text-gray-light ml-2 text-11"
            >{{ $t("Out of 5") }}</span
          >
        </div>
        <div class="text-right">
          <b-form-rating
            v-model="school.rating"
            color="#F04B4C"
            readonly
            class="w-25 mt-0 border-0"
          ></b-form-rating>
          <small class="d-block ml-3 pr-3 text-gray-light"
            >{{ school.rating | numeral("0.0") }} {{ $t("ratings") }}</small
          >
        </div>
      </b-row>
      <div class="mt-2">
        <table class="table table-borderless table-rating text-primary-dark">
          <tbody>
            <tr>
              <td width="5%">
                <span class="text-18 font-weight-bold">5</span>
              </td>
              <td>
                <b-progress
                  :value="
                    ratingSummary
                      ? (ratingSummary.rating['5'] * 100) / totalRating
                      : 0
                  "
                  :max="100"
                  height="3px"
                  class="mt-2 progress-rating"
                ></b-progress>
              </td>
              <td width="5%" class="text-12">
                {{
                  (ratingSummary
                    ? (ratingSummary.rating["5"] * 100) / totalRating
                    : 0) | numeral("0.0")
                }}%
              </td>
            </tr>
            <tr>
              <td width="5%">
                <span class="text-18 font-weight-bold">4</span>
              </td>
              <td>
                <b-progress
                  :value="
                    ratingSummary
                      ? (ratingSummary.rating['4'] * 100) / totalRating
                      : 0
                  "
                  :max="100"
                  height="3px"
                  class="mt-2 progress-rating"
                ></b-progress>
              </td>
              <td width="5%" class="text-12">
                {{
                  (ratingSummary
                    ? (ratingSummary.rating["4"] * 100) / totalRating
                    : 0) | numeral("0.0")
                }}%
              </td>
            </tr>
            <tr>
              <td width="5%">
                <span class="text-18 font-weight-bold">3</span>
              </td>
              <td>
                <b-progress
                  :value="
                    ratingSummary
                      ? (ratingSummary.rating['3'] * 100) / totalRating
                      : 0
                  "
                  :max="100"
                  height="3px"
                  class="mt-2 progress-rating"
                ></b-progress>
              </td>
              <td width="5%" class="text-12">
                {{
                  (ratingSummary
                    ? (ratingSummary.rating["3"] * 100) / totalRating
                    : 0) | numeral("0.0")
                }}%
              </td>
            </tr>
            <tr>
              <td width="5%">
                <span class="text-18 font-weight-bold">2</span>
              </td>
              <td>
                <b-progress
                  :value="
                    ratingSummary
                      ? (ratingSummary.rating['2'] * 100) / totalRating
                      : 0
                  "
                  :max="100"
                  height="3px"
                  class="mt-2 progress-rating"
                ></b-progress>
              </td>
              <td width="5%" class="text-12">
                {{
                  (ratingSummary
                    ? (ratingSummary.rating["2"] * 100) / totalRating
                    : 0) | numeral("0.0")
                }}%
              </td>
            </tr>
            <tr>
              <td width="5%">
                <span class="text-18 font-weight-bold">1</span>
              </td>
              <td>
                <b-progress
                  :value="
                    ratingSummary
                      ? (ratingSummary.rating['1'] * 100) / totalRating
                      : 0
                  "
                  :max="100"
                  height="3px"
                  class="mt-2 progress-rating"
                ></b-progress>
              </td>
              <td width="5%" class="text-12">
                {{
                  (ratingSummary
                    ? (ratingSummary.rating["1"] * 100) / totalRating
                    : 0) | numeral("0.0")
                }}%
              </td>
            </tr>
            <tr>
              <td width="5%">
                <span class="text-18 font-weight-bold">0</span>
              </td>
              <td>
                <b-progress
                  :value="ratingSummary ? ratingSummary.rating['0'] : 0"
                  :max="100"
                  height="3px"
                  class="mt-2 progress-rating"
                ></b-progress>
              </td>
              <td width="5%" class="text-12">
                {{
                  (ratingSummary
                    ? (ratingSummary.rating["0"] * 100) / totalRating
                    : 0) | numeral("0.0")
                }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-row
          v-if="
            myComment.user == null &&
            userProfile &&
            userProfile.id != school.user
          "
        >
          <b-col cols="3" lg="2" sm="2" class="px-0 px-lg-2">
            <div>
              <b-avatar
                variant="light"
                size="4.5rem"
                class="mt-2"
                :src="userProfile.profile.avatar"
              ></b-avatar>
            </div>
          </b-col>
          <b-col cols="9" lg="10" sm="10" class="pl-1">
            <span class="text-gray-light text-12">{{
              $t("Leave your comments and reviews below.")
            }}</span
            ><br />
            <ValidationObserver ref="formComment" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                <validation-provider :name="$t('ratings')" v-slot="{ errors }">
                  <b-form-rating
                    size="lg"
                    v-model="form.rating"
                    color="#F04B4C"
                    show-clear
                    class="mb-2 w-25 border-0"
                  ></b-form-rating>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  :name="$t('Comment')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="textarea"
                    :placeholder="$t('Add a public comment')"
                    rows="3"
                    max-rows="6"
                    v-model="form.comment"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-button
                  type="submit"
                  class="mt-2 float-right text-primary-dark"
                  :disabled="isLoading"
                  variant="outline-light"
                >
                  <b-spinner
                    small
                    class="mr-3"
                    v-if="isLoading"
                    label="Loading..."
                  />
                  {{ $t("Comment") }}
                </b-button>
              </b-form>
            </ValidationObserver>
            <br />
          </b-col>
        </b-row>
        <div v-for="result in resultItems" :key="result.id">
          <b-row class="mt-4 px-3 pb-2">
            <b-col sm="2" lg="2" col class="pl-1 pr-1">
              <b-avatar
                variant="light"
                size="4.5rem"
                :src="result.user_avatar"
              ></b-avatar>
            </b-col>
            <b-col sm="10" lg="10" col class="pl-0 align-items-center d-flex">
              <div>
                <span
                  class="text-14 text-primary-dark font-weight-bold text-capitalize"
                  >{{
                    result.user_first_name + " " + result.user_last_name
                  }}</span
                ><br />
                <div class="row text-13">
                  <div class="col-4">
                    <b-form-rating
                      v-model="result.rating"
                      readonly
                      color="#F04B4C"
                      size="sm"
                      class="p-0 ml-n1 mt-n1 border-0"
                      style="font-size: 0.7rem"
                    ></b-form-rating>
                  </div>
                  <div class="col-8">
                    <small class="text-12">{{
                      new Date(result.created_at)
                        | dateFormat("DD MMM YYYY, HH:mm", dateFormatConfig)
                    }}</small>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="px-0 pb-3 border-bottom">
            <span class="text-14">{{ result.comment }}</span>
          </div>
        </div>
        <b-button
          v-if="resultItems.length > 0 && nextPage"
          class="mt-4 text-danger-light px-3"
          @click="viewMore"
          variant="outline-light"
          >{{ $t("View more") }}</b-button
        >
        <div v-if="resultItems.length == 0" class="text-center">
          <span class="text-14">{{ $t("No results") }}</span>
        </div>
      </div>
    </b-col>
    <b-row class="text-center d-none">
      <span>{{ $t("No results") }}</span>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SchoolRatingAndComment",
  props: {
    school: Object,
    resultItems: Array,
    ratingSummary: Object,
    nextPage: String,
    myComment: Object,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        school: null,
        user: null,
        comment: null,
        rating: 0,
      },
      totalRating: 1,
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters.userProfile;
    },
  },
  mounted() {
    this.form.user = this.userProfile ? this.userProfile.id : null;
    this.form.school = this.school.id;
    const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);
    if (this.ratingSummary) {
      this.totalRating = sumValues(this.ratingSummary.rating);
    }
    console.log(this.totalRating);
  },
  methods: {
    viewMore() {
      this.$emit("viweMore");
    },
    onSubmit() {
      this.$refs.formComment.validate().then((result) => {
        if (result) {
          this.$emit("submitComment", this.form);
          requestAnimationFrame(() => {
            this.form.comment = "";
            this.form.rating = 0;
            this.$refs.formComment.reset();
          });
        }
      });
    },
  },
};
</script>
<style >
.progress-bar {
  background-color: #f04b4c !important;
}
.table-rating td {
  padding: 0.5rem !important;
}
</style>
