<template>
  <div>
    <b-container
      class="base-container-x contact-header-box"
      style="min-height: 500px; background-color: #fff"
    >
      <div class="mt-3">
        <b-col cols="12" class="p-2">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form
              @submit.stop.prevent="handleSubmit(onSubmit)"
              @reset.prevent="reset"
            >
              <div class="mt-2">
                <h5 class="text-primary-dark">{{ $t("Add Event") }}</h5>
              </div>
              <b-row>
                <b-col class="col col-sm-12 col-lg-6">
                  <b-row>
                    <b-col cols="12">
                      <div
                        v-if="!image"
                        class="border border-1 rounded d-flex justify-content-center align-items-center add-img-gallery"
                        style="height: 345px !important"
                      >
                        <span
                          class="position-absolute text-muted"
                          style="margin-top: 90px"
                          >Widht 900px X Height 506px</span
                        >
                        <div>
                          <b-icon
                            class="ml-1"
                            scale="3"
                            :title="$t('Add')"
                            style="color: #dbdbdb"
                            icon="image"
                          />
                        </div>
                      </div>
                      <div
                        v-else
                        class="border border-1 rounded d-flex justify-content-center align-items-center add-img-gallery"
                        style="height: 345px"
                      >
                        <b-img
                          :src="image"
                          fluid
                          style="max-height: 345px !important"
                        />
                      </div>
                      <validation-provider
                        :name="$t('Image')"
                        rules="required"
                        autocomplete="off"
                        v-slot="{ errors }"
                      >
                        <b-form-group
                          class="d-inline col col-12 px-0 mt-2 font-weight-bold text-primary-dark"
                        >
                          <label
                            for="input-image"
                            class="text-primary-dark font-weight-600"
                            >{{ $t("Image")
                            }}<span class="text-danger-light">*</span></label
                          >
                          <b-form-file
                            v-model="form.image"
                            :placeholder="$t('Choose a file or drop it here')"
                            :drop-placeholder="$t('Drop file here')"
                            type="file"
                            accept="image/*"
                            id="input-image"
                            @change="onFileChange"
                          ></b-form-file>
                          <p class="small text-muted p-1 mb-1">
                            {{
                              $t(
                                "Support image type and Image resolution, {width} {height}",
                                { width: "900px", height: "506px" }
                              )
                            }}
                          </p>
                          <small class="text-danger px-1">{{
                            errors[0]
                          }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="6" sm="12">
                  <b-row class="px-2">
                    <b-col cols="12">
                      <validation-provider
                        :name="$t('Subject')"
                        autocomplete="off"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="input-event-subject"
                          class="text-primary-dark font-weight-bold"
                          >{{ $t("Subject")
                          }}<span class="text-danger-light">*</span></label
                        >
                        <b-form-group
                          class="d-inline w-100 font-weight-bold text-primary-dark"
                        >
                          <b-form-input
                            id="input-event-subject"
                            type="text"
                            class="input-auth"
                            v-model="form.subject"
                            :placeholder="$t('Enter subject')"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <label
                        for="input-daterange"
                        class="text-primary-dark font-weight-bold"
                        >{{ $t("Date")
                        }}<span class="text-danger-light">*</span></label
                      >
                      <date-range-picker
                        id="input-daterange"
                        ref="datepicker"
                        :autoApply="true"
                        v-model="dateRange"
                        :ranges="false"
                        @update="updateValueDaterange"
                        class="w-100"
                      >
                        <template
                          v-slot:input="picker"
                          style="min-width: 350px"
                        >
                          {{ picker.startDate | dateFormat("YYYY-MM-DD") }}
                          {{ $t("To") }}
                          {{ picker.endDate | dateFormat("YYYY-MM-DD") }}
                        </template>
                      </date-range-picker>
                    </b-col>
                    <b-col lg="6" sm="12" class="mt-2">
                      <validation-provider
                        :name="$t('Start time')"
                        autocomplete="off"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="input-start-time"
                          class="text-primary-dark font-weight-bold"
                          >{{ $t("Start time")
                          }}<span class="text-danger-light">*</span></label
                        >
                        <timepicker
                          id="input-start-time"
                          type="minute"
                          v-model="form.start_time"
                          :timeStr="timeStr"
                          :locale="currentLanguage"
                          :placeholder="$t('No time selected')"
                          :btnStr="$t('Select')"
                          style="height: 36px"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6" sm="12" class="mt-2">
                      <validation-provider
                        :name="$t('End time')"
                        autocomplete="off"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="input-end-time"
                          class="text-primary-dark font-weight-bold"
                          >{{ $t("End time")
                          }}<span class="text-danger-light">*</span></label
                        >
                        <timepicker
                          id="input-end-time"
                          type="minute"
                          v-model="form.end_time"
                          :timeStr="timeStr"
                          :locale="currentLanguage"
                          :placeholder="$t('No time selected')"
                          :btnStr="$t('Select')"
                          style="height: 36px"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col lg="12" sm="12" class="mt-3">
                      <b-form-group>
                        <label
                          for="currency"
                          class="text-primary-dark font-weight-600"
                          >{{ $t("Currency") }}
                          <span class="text-danger-light">*</span></label
                        >
                        <validation-provider
                          :name="$t('Currency')"
                          rules="required"
                          autocomplete="off"
                          v-slot="{ errors }"
                        >
                          <b-form-select
                            id="currency"
                            v-model="form.currency"
                            :options="currencies"
                            value-field="code"
                            text-field="name"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled>{{
                                $t("Click Here")
                              }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="mt-3">
                      <validation-provider
                        :name="$t('Price')"
                        role="required|numeric|min_value:0"
                        autocomplete="off"
                        v-slot="{ errors }"
                      >
                        <b-form-group
                          class="d-inline w-100 font-weight-bold text-primary-dark"
                        >
                          <label
                            for="input-price"
                            class="text-primary-dark font-weight-600"
                            >{{ $t("Price")
                            }}<span class="text-danger-light">*</span></label
                          >
                          <b-form-input
                            id="input-price"
                            type="number"
                            class="input-auth"
                            v-model="form.price"
                            :placeholder="$t('Price')"
                          ></b-form-input>
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col lg="12" sm="12" class="">
                      <validation-provider
                        :name="$t('Limit Register')"
                        role="numeric"
                        autocomplete="off"
                        v-slot="{ errors }"
                      >
                        <b-form-group
                          class="d-inline w-100 font-weight-bold text-primary-dark"
                          for="input-event-subject"
                          :label="$t('Limit Register')"
                        >
                          <b-form-input
                            id="input-event-subject"
                            type="number"
                            class="input-auth"
                            v-model="form.limited"
                            :placeholder="$t('Limit Register')"
                          ></b-form-input>
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="px-0 pr-0 col-sm-12 col-lg-12">
                  <validation-provider
                    :name="$t('Description')"
                    rules="required"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      class="d-inline col-12 font-weight-bold text-primary-dark"
                    >
                      <label
                        for="input-description"
                        class="text-primary-dark font-weight-600"
                        >{{ $t("Description")
                        }}<span class="text-danger-light">*</span></label
                      >
                      <b-form-textarea
                        id="input-description"
                        type="text"
                        class="input-auth"
                        v-model="form.description"
                        rows="5"
                        max-rows="10"
                        :placeholder="$t('Enter Description')"
                      >
                      </b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="px-0 pr-0 col-sm-12 col-lg-12">
                  <validation-provider
                    :name="$t('Address')"
                    rules="required"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      class="d-inline col-12 font-weight-bold text-primary-dark"
                    >
                      <label
                        for="input-address"
                        class="text-primary-dark font-weight-600"
                        >{{ $t("Address")
                        }}<span class="text-danger-light">*</span></label
                      >
                      <b-form-input
                        id="input-address"
                        type="text"
                        v-model="form.address"
                        :placeholder="$t('Address')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <validation-provider
                    :name="$t('Location')"
                    rules="required"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label
                        for="location"
                        class="text-primary-dark font-weight-600"
                        >{{ $t("Location")
                        }}<span class="text-danger-light">*</span></label
                      >
                      <v-select
                        id="location"
                        class="form-v-selects"
                        :placeholder="$t('Province or States')"
                        label="text"
                        :filterable="false"
                        :options="regions"
                        v-model="selectedRegion"
                        @search="searchRegion"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search 11"
                            v-bind="attributes"
                            v-on="events"
                            @click="setReadOnly"
                          />
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{ $t("No results found for") }}
                            <em>{{ search }}</em
                            >.
                          </template>
                          <em style="opacity: 0.5" v-else>{{
                            $t("Typing to search for a Province or States")
                          }}</em>
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.text }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.text }}
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="px-0 col-sm-12 col-lg-6">
                  <validation-provider
                    :name="$t('Email')"
                    rules="required"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      class="d-inline col-12 pr-2 font-weight-bold text-primary-dark"
                    >
                      <label
                        for="input-email"
                        class="text-primary-dark font-weight-600"
                        >{{ $t("Email")
                        }}<span class="text-danger-light">*</span></label
                      >
                      <b-form-input
                        id="input-email"
                        type="email"
                        class="input-auth"
                        v-model="form.email"
                        :placeholder="$t('Email')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col class="px-0 col-sm-12 col-lg-6">
                  <validation-provider
                    :name="$t('Phone number')"
                    rules="numeric"
                    autocomplete="off"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      class="d-inline col-12 pl-2 font-weight-bold text-primary-dark"
                      for="input-phonenumber"
                      :label="$t('Phone number')"
                    >
                      <b-form-input
                        id="input-phonenumber"
                        type="text"
                        class="input-auth"
                        v-model="form.phone_number"
                        :placeholder="$t('Phone number')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <span class="text-18 font-weight-bold text-primary-dark">{{
                $t("Social media")
              }}</span
              ><br />
              <validation-provider
                :name="$t('Website')"
                rules=""
                autocomplete="off"
                v-slot="{ errors }"
              >
                <b-form-group
                  class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark"
                  for="input-website"
                  :label="$t('Website')"
                >
                  <b-form-input
                    id="input-website"
                    type="text"
                    class="input-auth"
                    v-model="form.website_url"
                    :placeholder="$t('Website')"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$t('Facebook URL')"
                rules=""
                autocomplete="off"
                v-slot="{ errors }"
              >
                <b-form-group
                  class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark"
                  for="input-facebook-graduated"
                  :label="$t('Facebook URL')"
                >
                  <b-form-input
                    id="input-facebook-graduated"
                    type="text"
                    class="input-auth"
                    v-model="form.facebook_url"
                    :placeholder="$t('Facebook URL')"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$t('Twitter URL')"
                rules=""
                autocomplete="off"
                v-slot="{ errors }"
              >
                <b-form-group
                  class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark"
                  for="input-twitter"
                  :label="$t('Twitter URL')"
                >
                  <b-form-input
                    id="input-twitter"
                    type="text"
                    class="input-auth"
                    v-model="form.twitter_url"
                    :placeholder="$t('Twitter URL')"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$t('Instagram URL')"
                rules=""
                autocomplete="off"
                v-slot="{ errors }"
              >
                <b-form-group
                  class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark"
                  for="input-instagram"
                  :label="$t('Instagram URL')"
                >
                  <b-form-input
                    id="input-instagram"
                    type="text"
                    class="input-auth"
                    v-model="form.instagram_url"
                    :placeholder="$t('Instagram URL')"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <b-form-checkbox
                v-model="form.is_active"
                value="1"
                unchecked-value="0"
              >
                {{ $t("Active") }}
              </b-form-checkbox>
              <div class="row">
                <b-col sm="12" lg="12" class="d-flex justify-content-center">
                  <b-button
                    pill
                    type="reset"
                    @click="setForm"
                    class="mt-2 px-5 my-4 btn-light font-weight-bold d-inline"
                    >{{ $t("Cancel") }}</b-button
                  >
                  <b-button
                    pill
                    type="submit"
                    :disabled="isLoading"
                    class="d-inline ml-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark"
                    ><b-spinner
                      small
                      class="mr-3"
                      v-if="isLoading"
                      label="Loading..."
                    />{{ $t("Save") }}</b-button
                  >
                </b-col>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
      </div>
    </b-container>
  </div>
</template>

<script>
import Api from "../../api/api";
import "vue-select/dist/vue-select.css";
import "@livelybone/vue-datepicker/lib/css/index.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "AddEvent",
  title: " | Add Events",
  components: { DateRangePicker },
  data() {
    return {
      isLoading: false,
      form: {
        id: null,
        school: null,
        teacher: null,
        image: null,
        email: null,
        start_date: null,
        end_date: null,
        start_time: "08:00",
        end_time: "09:00",
        currency: null,
        price: 0,
        limited: null,
        description: null,
        region: null,
        phone_number: null,
        website_url: null,
        facebook_url: null,
        twitter_url: null,
        instagram_url: null,
        is_active: 1,
      },
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      dateFormat: "mm/dd/yyyy",
      image: null,
      selectedRegion: null,
      regions: [],
      type: null,
      timeStr: ["hour", "min", "sec"],
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    schoolId() {
      return this.$store.getters.schoolId;
    },
    teacherId() {
      return this.$store.getters.teacherId;
    },
    currencies() {
      return this.$store.getters.currencies;
    },
  },
  async mounted() {
    this.type = this.$route.query.type;
    if (!this.teacherId && !this.schoolId) {
      this.$router.push({ name: "searchDirectory" });
    }

    if (this.type === "teacher") {
      this.form.teacher = this.teacherId;
    } else {
      this.form.school = this.schoolId;
    }
    if (!this.form.start_date) {
      this.form.start_date = this.$options.filters.dateFormat(
        this.dateRange.startDate,
        "YYYY-MM-DD"
      );
    }
    if (!this.form.end_date) {
      this.form.end_date = this.$options.filters.dateFormat(
        this.dateRange.endDate,
        "YYYY-MM-DD"
      );
    }
    // console.log('form', this.form)
  },
  watch: {
    selectedRegion: function (val) {
      this.form.region = val.value;
    },
  },
  methods: {
    updateValueDaterange(value) {
      this.form.start_date = this.$options.filters.dateFormat(
        value.startDate,
        "YYYY-MM-DD"
      );
      this.form.end_date = this.$options.filters.dateFormat(
        value.endDate,
        "YYYY-MM-DD"
      );
    },
    setReadOnly(el) {
      setTimeout(() => {
        el.target.removeAttribute("readonly");
      }, 80);
    },
    setForm() {
      requestAnimationFrame(() => {
        this.form = {
          id: null,
          image: null,
          email: null,
          start_date: null,
          end_date: null,
          start_time: null,
          end_time: null,
          price: 0,
          limited: null,
          description: null,
          region: null,
          phone_number: null,
          website_url: null,
          facebook_url: null,
          twitter_url: null,
          instagram_url: null,
          is_active: 1,
        };
        this.image = null;
        this.$refs.form.reset();
      });
    },
    onFileChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    onSubmit() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.image = null;
          this.saveEvent();
        }
      });
    },
    saveEvent() {
      Api.addEvent(this.form)
        .then((response) => {
          this.$notify({
            group: "success",
            title: this.$t("Add event"),
            text: this.$t("Successfully saved data"),
          });
          requestAnimationFrame(() => {
            this.$refs.form.reset();
            this.setForm();
          });
          this.isLoading = false;
          this.$router.push({
            name: "eventDetail",
            params: { eventId: response.data.id },
          });
        })
        .catch(() => {
          this.$notify({
            group: "error",
            title: this.$t("Add event"),
            text: this.$t("Unsuccessfully saved data"),
          });
          this.isLoading = false;
        });
    },
    searchRegion(searchText) {
      this.searchText = searchText;
      if (this.searchText) {
        this.regions = [];
        Api.searchRegion({ q: this.searchText }).then((response) => {
          if (response.data.results) {
            response.data.results.forEach((region) => {
              const url = region.url.split("/");
              this.regions.push({
                value: url[url.length - 2],
                text: region.display_name,
              });
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
.vs__dropdown-menu > li {
  padding: 5px !important;
  padding-left: 7px !important;
  border-bottom: 1px solid #f6f8fa;
}
.add-img-gallery {
  height: auto;
  max-height: 345px !important;
}
</style>
