<template>
  <div id="home">
    <b-container class="base-container-x">
      <div class="block-header">
        <div class="d-none d-lg-block inner-block-header"></div>
        <b-row class="inner-block-header">
          <b-col sm="12" md="6" lg="6" xl="6" class="mb-2">
            <span class="block-header-sub-title text-white">{{
              $t("International Yoga Organisation")
            }}</span>
            <h1
              class="block-header-title text-white mt-2 mt-md-3 font-weight-bold d-lg-block d-block"
            >
              {{ $t("WORLD YOGA ALLIANCE") }}
            </h1>
            <span
              class="block-header-description text-white mt-3 mt-md-2 mt-lg-3 text-truncate text-wrap d-block"
              >{{
                $t(
                  "World Yoga Alliance® is a Non-Profit Yoga Organization that aims to share the Traditional Indian Yoga Knowledge and create a healthy living community worldwide."
                )
              }}</span
            >
            <b-button
              v-if="!loggedIn"
              pill
              variant="outline-light"
              :to="{ name: 'authenSignup' }"
              class="mr-3 px-4 my-3 my-md-3 my-lg-4 font-weight-bold header-btn-register"
              >{{ $t("Create New Account") }}
              <span class="ml-1"
                ><font-awesome-icon :icon="['fas', 'arrow-right']" /></span
            ></b-button>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
            class="d-flex justify-content-center align-items-center container-video mt-n5 px-0"
          >
            <div class="player w-100">
              <vue-plyr :options="options" ref="player">
                <video
                  data-plyr-config="{'autoplay': true; 'volume': 0}"
                ></video>
              </vue-plyr>
              <!-- <vue-plyr :options="options" ref="player">
              <video
                controls
                playsinline
                crossorigin
                data-poster="poster.jpg"
              >
                <source
                  size="720"
                  src="https://stream.wyayoga.com/yoga/production/23/1620207332.m3u8"
                  type="application/x-mpegURL"
                />
              </video>
            </vue-plyr> -->
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <div class="container-fluid home section-1 pr-0 pl-0 pb-4">
      <b-container class="base-container-x container align-self-center pb-4">
        <b-row>
          <b-col lg="12" class="block-search px-0">
            <BlockSearch @submit="onSubmitSeachSchool" />
          </b-col>
          <b-col sm="12" md="6" class="pt-5 d-block ourmission">
            <div>
              <router-link :to="{ name: 'aboutUs' }"
                ><h1 class="section-header-title">
                  <span class="text-danger d-inline-block font-weight-300">{{
                    $t("WYA MISSION")
                  }}</span>
                </h1></router-link
              >
              <h4
                class="section-sub-header-title sub-header-box text-primary-light d-block mt-lg-4 font-weight-400"
              >
                {{
                  $t(
                    "To share the Traditional Indian Yoga Knowledge and create a healthy living community worldwide."
                  )
                }}
              </h4>
              <p class="article-content desc-box text-gray-light d-block mt-3">
                {{
                  $t(
                    "Our mission is to share the passion of both teachings and practicing of yoga based on Traditional Indian Yoga knowledge as well as providing friendly support to maintain unity in the diversity for every yoga style. We aim to create a healthy living community worldwide."
                  )
                }}<br /><br />
                {{
                  $t(
                    "We are committed to promote and support the yoga community for teachers/ schools and also spread the authentic yoga principles, standards and practice. We keep maintaining international standards of yoga education as we want to make sure that all of our members are provided with necessary skills, knowledge, and proper guidance to become professional yoga teachers/ schools living and cultivating a healthy yogic lifestyle."
                  )
                }}<br /><br />
                {{
                  $t(
                    "Moreover, we are happy to offer extensive chances for self-development along with future job opportunities to our community members at any WYA authorized yoga schools around the world."
                  )
                }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="clearfix"></div>
    <div class="container-fluid bg-white">
      <div class="container">
        <b-row class="bg-section-2 section-2 ourvision">
          <b-col sm="6" md="6" class="px-0 banner">
            <b-img
              alt="WYA Yoga"
              class="w-100"
              :src="require('../assets/images/banner/WYA_our_vision.png')"
            ></b-img>
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="pt-2 pl-3 pl-sm-3 pl-md-4 d-flex align-items-center"
          >
            <div class="d-inline">
              <router-link :to="{ name: 'aboutUs' }"
                ><h1 class="section-header-title">
                  <span class="text-danger d-inline-block font-weight-300">{{
                    $t("OUR VISION")
                  }}</span>
                </h1></router-link
              >
              <h4
                class="section-sub-header-title text-primary-light d-block mt-lg-4 font-weight-400"
              >
                {{
                  $t(
                    "We maintain the highest education quality and standards worldwide."
                  )
                }}
              </h4>
              <br />
              <b-col sm="12" md="8" class="px-0">
                <p class="article-content text-gray-light d-block">
                  {{
                    $t(
                      "As yoga is a system of unity and diversity, our vision is to build a worldwide network of yoga teachers and schools by providing an opportunity to join an international registry, and to promote the ones who have the same yoga belief and ethics as us. We value so much our authorized teachers, therapists, and schools in the yoga industry and always wish to help them improve their knowledge and spiritual growth, by giving them a chance to join our yoga events, workshops and seminars worldwide."
                    )
                  }}
                </p>
              </b-col>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="container base-container-x">
      <!-- <div class="d-flex justify-content-center mt-lg-3 mb-5">
      <h2 class="text-primary-dark d-sm-flex d-none justify-content-center">{{ $t('UNITY IN DIVERSITY') }}</h2>
      <h3 class="text-primary-dark d-flex d-sm-none justify-content-center">{{ $t('UNITY IN DIVERSITY') }}</h3>
    </div> -->
      <b-row class="section-3 ml-0 mr-0">
        <b-col lg="4" class="text-center">
          <h1 class="text-primary-dark">
            <strong>{{ $t("WHY") }}</strong>
          </h1>
          <p>
            {{ $t("We stand to promote Traditional Indian Yoga Knowledge.") }}
          </p>
        </b-col>
        <b-col lg="4" class="text-center">
          <h1 class="text-primary-dark">
            <strong>{{ $t("HOW") }}</strong>
          </h1>
          <p>
            {{
              $t(
                "We are the ONE who  maintains high quality Yoga education standards worldwide."
              )
            }}
          </p>
        </b-col>
        <b-col lg="4" class="text-center">
          <h1 class="text-primary-dark">
            <strong>{{ $t("WHAT") }}</strong>
          </h1>
          <p>{{ $t("We provide both annual and lifetime license.") }}</p>
        </b-col>
      </b-row>
      <!-- <b-row class="section-3 d-inline d-flex justify-content-center align-items-center">
      <b-col lg="6" sm="12" class="px-sm-0 mt-4">
        <b-row>
        <div class="col-lg-8 px-0 mt-lg-3">
          <h2 class="text-primary-dark d-sm-flex d-none justify-content-center">{{ $t('UNITY IN DIVERSITY') }}</h2>
          <h3 class="text-primary-dark d-flex d-sm-none justify-content-center">{{ $t('UNITY IN DIVERSITY') }}</h3>
        </div>
        <div class="col-lg-4 col-sm-12 px-sm-0">
            <div class="text-center"><h1 class="text-danger-light display-3 font-weight-bold">80 +</h1>
            </div>
            <span><h5 class="d-block text-gray-light text-center">{{ $t('Countries') }}</h5></span>
        </div>
        </b-row>
      </b-col>
      <b-col lg="2" sm="12" class="text-center mt-4">
        <b-img alt="WYA Yoga video" :src="require('../assets/images/banner/RTY.png')"></b-img>
      </b-col>
      <b-col lg="2" sm="12" class="text-center mt-4">
        <b-img alt="WYA Yoga video" :src="require('../assets/images/banner/WYA.png')"></b-img>
      </b-col>
      <b-col lg="2" sm="12" class="text-center mt-4">
        <b-img alt="WYA Yoga video" :src="require('../assets/images/banner/RSY.png')"></b-img>
      </b-col>
    </b-row> -->
    </div>
    <div class="container base-container-x mb-4">
      <div class="banner our-standards">
        <router-link :to="{ name: 'standards' }"
          ><h3 class="caption text-uppercase">
            {{ $t("Our Standards") }}
          </h3></router-link
        >
      </div>
      <div class="banner online-training">
        <router-link :to="{ name: 'OYCP' }"
          ><h3 class="caption text-uppercase">
            {{ $t("Looking for an online training accreditation?") }}
          </h3></router-link
        >
      </div>
      <div class="banner join-us">
        <router-link :to="{ name: 'authenSignup' }"
          ><h3 class="caption text-uppercase">
            {{ $t("Join us now") }}
          </h3></router-link
        >
      </div>
    </div>
    <div class="wya-certified-list container base-container-x py-4 mb-4">
      <b-row class="align-self-center">
        <b-col lg="12" sm="12" class="pb-2">
          <h1
            class="section-header-title text-primary-dark d-flex justify-content-center"
          >
            {{ $t("WYA CERTIFIED YOGA SCHOOLS (CYS)") }}
          </h1>
          <br />
          <h5
            class="section-sub-header-title text-gray-light d-flex justify-content-center pt-2"
          >
            {{
              $t(
                "Know more about our certified yoga school members, visit their official school profile here."
              )
            }}
          </h5>
        </b-col>
      </b-row>
      <b-skeleton-wrapper>
        <template>
          <div v-if="isLoadingRecommended">
            <b-card class="border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </div>
        </template>
        <div v-if="!isLoadingRecommended && schooolRecommended">
          <b-row v-if="schooolRecommended && schooolRecommended.length > 0">
            <b-col
              lg="3"
              sm="6"
              cols="6"
              md="4"
              class="box-item mt-3 px-2"
              v-for="item in schooolRecommended"
              :key="item.id"
            >
              <div class="item item-popular rounded-lg">
                <div class="text-center bg-white" style="posion: relative">
                  <b-link
                    :to="{
                      name: 'schoolProfileDetail',
                      params: { schoolId: item.id },
                    }"
                  >
                    <b-img
                      blank-color="#fff"
                      class="w-100 border-0 rounded-top img-item"
                      :src="
                        item.doc_logo || item.doc_cover
                          ? item.doc_logo || item.doc_cover
                          : PUBLIC_PATH + 'img/icons/android-chrome-512x512.png'
                      "
                    ></b-img>
                  </b-link>
                </div>
                <div class="p-3">
                  <p class="text-danger-light text-13 text-uppercase">
                    {{ item.region_name }}
                  </p>
                  <h5 class="text-primary-dark font-weight-600 text-15">
                    <b-link
                      :to="{
                        name: 'schoolProfileDetail',
                        params: { schoolId: item.id },
                      }"
                      >{{ item.school_name }}</b-link
                    >
                  </h5>
                  <small class="text-gray-light">
                    <span class="pr-1">{{ $t("Member Since") }} :</span
                    ><span class="text-primary-dark">{{
                      new Date(item.member_since)
                        | dateFormat("DD MMMM YYYY", dateFormatConfig)
                    }}</span>
                  </small>
                  <br />
                  <b-button
                    pill
                    variant="link"
                    :to="{
                      name: 'schoolProfileDetail',
                      params: { schoolId: item.id },
                    }"
                    class="font-weight-bold text-decoration-none text-primary-light pl-0 mt-2 d-inline-block text-14"
                    >{{ $t("Read more") }}
                    <font-awesome-icon :icon="['fas', 'angle-right']"
                  /></b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-skeleton-wrapper>
      <!-- <vue-horizontal-list v-if="schooolRecommended.length > 0" :items="schooolRecommended" :options="options">
      <template v-slot:default="{item}">
        <div class="item item-popular rounded-lg">
          <div class="item-img text-center" style="posion:relative">
            <router-link :to="{ name: 'schoolProfileDetail', params: { schoolId: item.id } }"><b-img  blank-color="#fff" class="border-0 rounded-top img-item" style="object-position: center; height: 200px; width: 265px;" :src="item.doc_logo ? item.doc_logo : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-img></router-link>
          </div>
          <div class="p-3">
            <p class="text-danger-light">{{ item.region_name }}</p>
            <h5 class="text-primary-dark">{{ item.school_name }}</h5>
            <span class="small text-gray-light">{{ $t('Member Since') }} :</span> <span class="small text-primary-dark">{{ new Date(item.member_since) | dateFormat('DD MMMM YYYY', dateFormatConfig) }}</span><br>
            <b-button pill variant="link" :to="{ name: 'schoolProfileDetail', params: { schoolId: item.id } }" class="font-weight-bold text-primary-light pl-0">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
          </div>
        </div>
      </template>
    </vue-horizontal-list> -->
    </div>
    <div class="wya-certified-list container base-container-x py-4 mb-4 d-none">
      <b-row class="align-self-center">
        <b-col lg="12" sm="12" class="pb-2">
          <h1
            class="section-header-title text-primary-dark d-flex justify-content-center"
          >
            {{ $t("WYA CERTIFIED YOGA SCHOOLS (CYS)") }}
          </h1>
          <br />
          <h5
            class="section-sub-header-title text-gray-light d-flex justify-content-center pt-2"
          >
            {{
              $t(
                "Know more about our certified yoga school members, visit their official school profile here."
              )
            }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" sm="12">
          <b-row>
            <b-col v-if="schools.length > 0" lg="6" sm="12" class="px-0 mt-4">
              <b-card
                sub-title-tag="h3"
                style="max-width: 100%; border: none"
                class="mb-2"
              >
                <b-link
                  :to="{
                    name: 'schoolProfileDetail',
                    params: { schoolId: schools[0].id },
                  }"
                  class="text-primary-light"
                >
                  <b-card-img
                    :src="
                      schools[0].doc_logo
                        ? schools[0].doc_logo
                        : require('../assets/images/banner/school_ex_1.png')
                    "
                    alt="Image"
                    class="rounded-0"
                  ></b-card-img>
                </b-link>
                <b-card-body>
                  <b-card-title title-tag="h5">{{
                    schools[0].region_name
                  }}</b-card-title>
                  <b-card-sub-title>
                    <div class="d-flex d-block justify-content-between">
                      <h3 class="section-header-title">
                        <b-link
                          :to="{
                            name: 'schoolProfileDetail',
                            params: { schoolId: schools[0].id },
                          }"
                          class="text-primary-light"
                          >{{ schools[0].school_name }}</b-link
                        >
                      </h3>
                      <b-button
                        href="#"
                        pill
                        variant="primary"
                        :to="{
                          name: 'schoolProfileDetail',
                          params: { schoolId: schools[0].id },
                        }"
                        class="font-weight-bold btn-read-more px-lg-5"
                        >{{ $t("Read more") }}</b-button
                      >
                    </div>
                    <p class="float-left text-gray-light">
                      {{
                        new Date(schools[0].member_since)
                          | dateFormat("MMM DD YYYY", dateFormatConfig)
                      }}
                    </p>
                  </b-card-sub-title>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col lg="6" sm="12" class="list mt-4">
              <b-container>
                <template v-for="(school, index) in schools">
                  <b-row v-if="index > 0" :key="school.id" class="mb-4">
                    <b-col xl="5" lg="6" sm="6" col class="px-0">
                      <b-link
                        :to="{
                          name: 'schoolProfileDetail',
                          params: { schoolId: school.id },
                        }"
                        class="text-primary-light"
                      >
                        <b-img
                          rounded
                          class="d-sm-block d-lg-none d-md-none d-xl-none"
                          :src="
                            schools[index].doc_logo
                              ? schools[index].doc_logo
                              : require('../assets/images/banner/school_ex_1.png')
                          "
                        ></b-img>
                        <b-img
                          rounded
                          class="d-none d-lg-block d-md-block d-xl-block"
                          :src="
                            schools[index].doc_logo
                              ? schools[index].doc_logo
                              : require('../assets/images/banner/school_ex_1.png')
                          "
                        ></b-img>
                        <!-- <b-img rounded fluid class="d-sm-block d-lg-none d-md-none d-xl-none" :src="require('../assets/images/banner/school_ex_1.png')"></b-img> -->
                        <!-- <b-img rounded  class="d-none d-lg-block d-md-block d-xl-block" :src="require('../assets/images/banner/school_ex_1.png')"></b-img> -->
                      </b-link>
                    </b-col>
                    <b-col
                      sm="6"
                      lg="6"
                      xl="7"
                      col
                      class="float-left d-flex align-items-center pl-lg-0 pl-sm-0"
                    >
                      <div>
                        <h5 class="text-danger-light mt-lg-2">
                          {{ school.region_name }}
                        </h5>
                        <h5
                          v-if="schools"
                          class="text-primary-light font-weight-600"
                        >
                          <b-link
                            :to="{
                              name: 'schoolProfileDetail',
                              params: { schoolId: school.id },
                            }"
                            class="text-primary-light"
                            >{{ schools[index].school_name }} sdfsdf</b-link
                          >
                        </h5>
                        <span class="float-left text-gray-light">{{
                          new Date(school.member_since)
                            | dateFormat("MMM DD YYYY", dateFormatConfig)
                        }}</span
                        ><br />
                        <b-button
                          href="#"
                          pill
                          variant="link"
                          :to="{
                            name: 'schoolProfileDetail',
                            params: { schoolId: school.id },
                          }"
                          class="font-weight-bold text-primary-light pl-0 mt-lg-2"
                          >{{ $t("Read more") }}
                          <font-awesome-icon :icon="['fas', 'angle-right']"
                        /></b-button>
                      </div>
                    </b-col>
                  </b-row>
                </template>
                <!-- <b-row class="mb-4">
              <b-col xl="5" lg="6" sm="6" col class="px-0">
                <b-img rounded fluid class="d-sm-block d-lg-none d-md-none d-xl-none" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>
                <b-img rounded class="d-none d-lg-block d-md-block d-xl-block" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>              </b-col>
              <b-col sm="6" lg="6" xl="7" col class="float-left d-flex align-items-center pl-lg-0 pl-sm-0">
                <div>
                  <h5 class="text-danger-light mt-lg-2">Thailand</h5>
                  <h5 class="text-primary-light font-weight-600">YOGA AROGYA</h5>
                  <span class="float-left text-gray-light">Oct 19 2020</span><br>
                  <b-button href="#" pill variant="link" class="font-weight-bold text-primary-light pl-0 mt-lg-2">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col xl="5" lg="6" sm="6" col class="px-0">
                <b-img rounded fluid class="d-sm-block d-lg-none d-md-none d-xl-none" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>
                <b-img rounded class="d-none d-lg-block d-md-block d-xl-block" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>              </b-col>
              <b-col sm="6" lg="6" xl="7" col class="float-left d-flex align-items-center pl-lg-0 pl-sm-0">
                <div>
                  <h5 class="text-danger-light mt-lg-2">Thailand</h5>
                  <h5 class="text-primary-light font-weight-600">YOGA AROGYA</h5>
                  <span class="float-left text-gray-light">Oct 19 2020</span><br>
                  <b-button href="#" pill variant="link" class="font-weight-bold text-primary-light pl-0 mt-lg-2">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
                </div>
              </b-col>
            </b-row> -->
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="section-4 testimonials">
      <div class="py-4 bg-white-transparent">
        <div class="container">
          <div class="row">
            <b-col lg="12" sm="12" class="px-0">
              <h1
                id="wya-testimonial"
                class="section-header-title text-primary-dark d-flex justify-content-center text-uppercase"
              >
                {{ $t("Testimonials") }}
              </h1>
              <br />
              <h5 class="section-sub-header-title text-gray-light text-center">
                {{ $t("Let’s hear what our members are talking about us.") }}
              </h5>
            </b-col>
            <b-col col class="d-block mt-3">
              <b-carousel
                id="carousel-1"
                :interval="4000"
                indicators
                background="#ffffff00"
                img-width="1024"
                :img-height="1"
                style="min-height: 400px"
              >
                <b-carousel-slide
                  v-for="item in testimonials"
                  :key="item.id"
                  img-blank
                >
                  <b-row>
                    <b-col md="1">
                      <h1 class="text-danger-light">“</h1>
                    </b-col>
                    <b-col md="10">
                      <p class="testimonial-text">
                        <i class="text-wrap">{{ item.message }}</i>
                      </p>
                    </b-col>
                    <b-col md="1">
                      <h1 class="text-danger-light">”</h1>
                    </b-col>
                    <b-col md="12" sm="12">
                      <b-avatar fluid :src="item.avatar" size="7rem"></b-avatar>
                      <h4 class="text-primary-dark mt-3">
                        {{ item.testimonial_name }}
                      </h4>
                      <h5>
                        <span class="text-danger-light"
                          >{{ item.region_country_name }},
                          {{ item.region_name }}</span
                        >
                        <span class="text-gray-light" v-if="item.school_name">
                          – </span
                        ><a
                          class="text-gray-light"
                          :href="item.website_url"
                          target="_blank"
                          >{{ item.school_name }}</a
                        >
                      </h5>
                    </b-col>
                  </b-row>
                </b-carousel-slide>

                <!-- <b-carousel-slide img-blank>
              <b-row>
                <b-col sm="1" md="1" lg="1">
                  <h1 class="text-danger-light">“</h1>
                </b-col>
                <b-col sm="10" md="10" lg="10">
                  <p class="testimonial-text"><i class="text-wrap">We are very proud to be part of this organization, that offers very high standards of training in all its schools worldwide and also constant and immediate support. Our school Styleoga highly recommends WYA.</i></p>
                </b-col>
                <b-col sm="1" md="1" lg="1">
                  <h1 class="text-danger-light">”</h1>
                </b-col>
                <b-col md="12" sm="12">
                  <b-avatar fluid :src="require('../assets/images/profile/demo_test_profile.png')" size="7rem"></b-avatar>
                  <h4 class="text-primary-dark mt-3">Tester</h4>
                  <h5><span class="text-danger-light">Thailand</span> – <span class="text-gray-light">Bangkok</span></h5>
                </b-col>
              </b-row>
            </b-carousel-slide> -->
                <!-- <b-carousel-slide img-blank>
              <b-row>
                <b-col md="1">
                  <h1 class="text-danger-light">“</h1>
                </b-col>
                <b-col md="10">
                  <p class="testimonial-text"><i class="text-wrap">We are very proud to be part of this organization, that offers very high standards of training in all its schools worldwide and also constant and immediate support. Our school Styleoga highly recommends WYA.</i></p>
                </b-col>
                <b-col md="1">
                  <h1 class="text-danger-light">”</h1>
                </b-col>
                </b-row>
                <b-row>
                <b-col md="12" sm="12">
                  <b-avatar fluid :src="require('../assets/images/profile/demo_test_profile_1.png')" size="7rem"></b-avatar>
                  <h4 class="text-primary-dark mt-3">Luigi Xuereb</h4>
                  <h5><span class="text-danger-light">ITALY</span> – <span class="text-gray-light">Styleoga</span></h5>
                </b-col>
              </b-row>
            </b-carousel-slide> -->
              </b-carousel>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid section-5 subscribe px-0 d-none">
      <div class="inner-backbrop">
        <div class="base-container-x container">
          <b-row class="section-5-content">
            <b-col md="4" sm="12" class="pl-lg-5 px-0 mb-4 mb-lg-0 mb-md-0">
              <h3 class="text-white d-block mt-5">{{ $t("Newsletter") }}</h3>
              <br />
              <h5 class="text-white d-block">
                {{ $t("Subscribe to our newsletter") }}
              </h5>
            </b-col>
            <b-col
              md="4"
              sm="12"
              class="d-flex align-items-center px-0 mb-4 mb-lg-0 mb-md-0"
            >
              <b-form-input
                size="lg"
                class="bg-transparent text-white input-subscribe border-white"
                :placeholder="$t('Enter your e-mail address')"
              ></b-form-input>
            </b-col>
            <b-col md="3" sm="12" class="d-flex align-items-center px-0">
              <b-button
                pill
                size="lg"
                class="btn-subscribe ml-lg-5 px-5 font-weight-bold btn-block"
                >{{ $t("Subscribe") }}</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="//vjs.zencdn.net/7.10.2/video.min.js"></script>
<script>
import Api from "../api/api";
import BlockSearch from "../components/search/BlockSearch.vue";
import VuePlyr from "vue-plyr";
import Hls from "hls.js";

export default {
  name: "Home",
  title: " | International Yoga Teachers Schools Directory",
  components: {
    BlockSearch,
    VuePlyr,
  },
  data() {
    return {
      heightWindow: window.innerWidth,
      schools: [],
      testimonials: [],
      schooolRecommended: null,
      isLoadingRecommended: false,
      options: {
        autoplay: true,
        // ratio: '16:9',
        volume: 0,
        loop: { active: false },
      },
      // options: {
      //   responsive: [
      //     { end: 576, size: 1 },
      //     { start: 576, end: 768, size: 2 },
      //     { start: 768, end: 992, size: 3 },
      //     { size: 4 }
      //   ],
      //   list: {
      //     // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
      //     windowed: 1200,
      //     // Because: #app {padding: 80px 24px;}
      //     padding: 10
      //   }
      // },
      videoId: 3, // Default home video
      urlStream:
        "https://stream.wyayoga.org/yoga/home/WYA_Organisation_29fps01speechinthemiddle_fixedII/1620207332_720p.m3u8",
    };
  },
  async mounted() {
    this.toggleNavbarNavClass("addClass", "nav-text-shadow");
    // this.getVideoDetail(this.videoId)
    this.getVideoDetail();
    this.$store.dispatch("resetFormSearchDirectory");
    this.getListSchool();
    this.getListTestimonial();
    this.getSchoolRecommended();
    // window.scrollTo(0, 0)
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    player() {
      return this.$refs.player.player;
    },
  },
  methods: {
    getSchoolRecommended() {
      const payload = {
        is_recommended: 1,
        limit: 8,
        offset: 0,
      };
      this.isLoadingRecommended = true;
      Api.listSchool(payload)
        .then((response) => {
          if (response.data.results) {
            this.isLoadingRecommended = false;
            this.schooolRecommended = response.data.results;
          }
        })
        .catch(() => {
          this.isLoadingRecommended = false;
        });
    },
    onSubmitSeachSchool(value) {
      this.$store
        .dispatch("updateFormSearchDirectory", value)
        .then((response) => {
          this.$router.push({ name: "searchDirectory" });
        });
    },
    toggleNavbarNavClass(addRemoveClass, className) {
      const nav = document.querySelector(".navbar-nav");
      if (addRemoveClass === "addClass") {
        if (nav) nav.classList.add(className);
      } else {
        if (nav) nav.classList.remove(className);
      }
    },
    getListSchool() {
      Api.listSchool({ limit: 4 }).then((response) => {
        if (response.data.results) {
          this.schools = [...response.data.results];
        }
      });
    },
    getListTestimonial() {
      Api.listTestimonial().then((response) => {
        if (response.data.results) {
          this.testimonials = [...response.data.results];
        }
      });
    },
    getVideoDetail() {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(this.urlStream);
        hls.attachMedia(this.player.media);
        window.hls = hls;
      }
    },
    // getVideoDetail (id) {
    //   Api.videoDetail({id: id}).then(response => {
    //     if (response.data) {
    //       this.video = response.data
    //       if (Hls.isSupported()) {
    //         const hls = new Hls()
    //         hls.loadSource(response.data.url_stream)
    //         hls.attachMedia(this.player.media)
    //         window.hls = hls
    //       }
    //     }
    //     this.loading = false
    //   }).catch((error) => {
    //     this.loading = false
    //     if (error.response.status !== 200 ) {
    //       this.$router.push({ name: 'videos' })
    //     }
    //   })
    // }
  },
  destroyed() {
    this.toggleNavbarNavClass("removeClass", "nav-text-shadow");
  },
};
</script>
<style scoped>
#home {
  /* Lock Text */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.block-header {
  /*margin-bottom: 100px;*/
}
.header-btn-register {
  border: 2px solid !important;
}
.header-cicle-play {
  display: block;
  padding: 20px 20px 23px 23px !important;
  height: 60px;
  width: 60px;
  margin-top: -30px;
}
.block-btn-play {
  position: absolute;
}
/*.container-video {
    display: flex;
    justify-content: center;
    align-items: center;
  }*/
.bg-section-2 {
  min-height: 600px !important;
}
.section-2 p,
.section-1 p {
  font-size: 1.3rem;
}
.add-icon {
  font-size: 40px;
}
.section-3 {
  background-color: #f2f2f2;
  padding: 50px;
}
.section-4-title {
  font-size: 5vw;
}
.card-title {
  color: #f04b4c !important;
  font-size: 1rem;
}
.btn-read-more,
.btn-subscribe {
  background-color: #3b6098 !important;
  border-color: #3b6098 !important;
}
.section-4 {
  background-image: url("../assets/images/banner/bg_section_4.png");
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
}
.section-5 {
  background-image: url("../assets/images/banner/newsletter_bg.png");
  background-repeat: no-repeat;
  background-color: white;
  min-height: 250px;
  background-position: center;
}
.section-5 .inner-backbrop {
  background-color: #213159a6;
  opacity: 1;
  width: 100%;
  min-height: 250px;
}
.section-5-content {
  min-height: 250px;
}
.testimonial-text {
  font-size: 1.2rem;
  color: #2c3e50;
  text-shadow: none;
}
.input-subscribe::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}
.input-subscribe:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
.input-subscribe::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
.block-search {
  /*margin-top: -70px;*/
}
.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  /* background-position: center; */
  min-height: 150px;
  position: relative;
  text-align: center;
}
.banner.our-standards {
  background-image: url("../assets/images/banner/our_standards.png");
}
.banner.online-training {
  background-image: url("../assets/images/banner/online_training.png");
}
.banner.join-us {
  background-image: url("../assets/images/banner/join_us.png");
}
.our-standards .caption,
.join-us .caption {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 5px;
  font-weight: bold;
}
.online-training .caption {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 5px;
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .banner.our-standards {
    background-position: right;
  }
  .banner.online-training {
    background-position: left;
  }
  .banner.join-us {
    background-position: right;
  }
  .our-standards .caption,
  .join-us .caption {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .online-training .caption {
    top: 50%;
    left: 50%;
    /* right: 15%; */
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .banner.online-training {
    background-position: right;
  }
  .our-standards .caption,
  .join-us .caption {
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
  .online-training .caption {
    top: 50%;
    left: 28%;
    right: 15%;
    transform: translate(-50%, -50%);
  }
}
.caption:hover {
  color: black;
}
</style>
